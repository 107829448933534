import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Text from './Text'
import ReactHtmlParser from 'react-html-parser'

const Paragraph = ({ children, className, html, ...props }) => {
  return (
    <ParagraphContainer className={className}>
      <Text xs={14} xl={16} xxl={18} light justify spacing={0.5} lineHeightMultiplier={17 / 14} {...props}>
        {html ? ReactHtmlParser(children) : children}
      </Text>
    </ParagraphContainer>
  )
}

Paragraph.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  html: PropTypes.bool,
}

export default Paragraph

const ParagraphContainer = styled.div`
  ${({ theme }) => css`
    width: 70%;
    margin: 0 auto;

    ${theme.media.lg`
      max-width: ${theme.space(32)};
      width: 100%;

    `}
  `}
`
