import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css, useTheme } from 'styled-components'
import { motion } from 'framer-motion'
import Text from './Text'
import { useAppContext } from '../../context/AppContext'
import useTelemetry from '../../hooks/useTelemetry'
import { radToDeg } from '../../utils/maths'

const DRAG_FRICTION = 0.04
const DRAG_FRICTION_INNER = 0.08

const Title = ({ children, vertical, as }) => {
  const theme = useTheme()
  const container = useRef()
  const { x, y } = useTelemetry()
  const { isLarge } = useAppContext()

  const sharedProps = {
    xs: 90,
    lg: 150,
    xl: 220,
  }

  const offsetX = vertical ? -y : x
  const offsetY = vertical ? x : y

  return (
    <Container ref={container}>
      <Inner
        style={{
          transform: `rotate(${vertical ? 270 : 0}deg)`,
        }}
      >
        <Text.motion
          as={motion[as]}
          uppercase
          center
          animate={{ opacity: 0.2, color: theme.color.primary }}
          thin
          {...sharedProps}
          style={{
            transform: `translate(${-offsetX * DRAG_FRICTION}px,${-offsetY * DRAG_FRICTION}px)`,
          }}
        >
          {children}
        </Text.motion>
        <Text.motion
          uppercase
          center
          animate={{ opacity: 0.1, color: theme.color.primary }}
          serif
          spacing={isLarge ? 8 : 3}
          style={{
            transform: `translate(${-offsetX * DRAG_FRICTION_INNER}px,${-offsetY * DRAG_FRICTION_INNER}px)`,
          }}
          {...sharedProps}
        >
          {children}
        </Text.motion>
      </Inner>
    </Container>
  )
}

Title.propTypes = {
  children: PropTypes.string,
  vertical: PropTypes.bool,
  as: PropTypes.string,
}

Title.defaultProps = {
  rotate: 0,
  as: 'h2',
}

export default Title

const Inner = styled.div(({ theme }) => css``)

const Container = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    user-select: none;
    pointer-events: none;
    z-index: 100;
    > ${Inner} > * {
      opacity: 0;
      &:last-child {
        position: absolute;
        top: ${theme.space(1)};
        left: ${theme.space(0.5)};

        ${theme.media.lg`
            top: ${theme.space(2)};
            left: ${theme.space(1)};
          `}
      }
    }
  `}
`
