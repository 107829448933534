import { useAppContext } from '../context/AppContext'
import { useMemo } from 'react'

export default () => {
  const {
    telemetry: { angle, distance, x, y },
  } = useAppContext()

  return useMemo(
    () => ({
      angle,
      distance,
      x,
      y,
    }),
    [angle, distance, x, y],
  )
}
